<template>
  <v-card class>
    <v-card-title class="bg-clr p-t-0">
      <v-layout justify-space-around wrap>
        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword">
            Total Customers
            <span class="count">{{ dashboard.total }}</span></span
          >
        </v-avatar>

        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword">
            Customers Today
            <span class="count">{{ dashboard.today }}</span></span
          >
        </v-avatar>
      </v-layout>

      <v-btn
        v-permissions="'add-new-customer'"
        style="background: orange !important"
        flat
        prepend-icon
        color="white darken-1"
        v-if="selectedCustomer.length && tabFilter == 'true'"
        @click="CheckboxSelectedIb()"
      >
        Send Data To IB
      </v-btn>
      <v-btn
        v-permissions="'add-new-customer'"
        style="background: orange !important"
        flat
        prepend-icon
        v-if="!selectedCustomer.length && tabFilter == 'true'"
        color="white darken-1"
        @click="SendIBPopup()"
      >
        Send Data IB
      </v-btn>

      <v-btn
        v-permissions="'add-new-customer'"
        class="white--text"
        flat
        color="orange darken-1"
        @click="check()"
      >
        <v-icon>add</v-icon>&nbsp;Add New Customer
      </v-btn>
    </v-card-title>
    <SelectCountry
      :open="selectCountryDialog"
      @closeSelectCountry="selectCountryDialog = false"
      :type="'/customer/new'"
    />

    <v-dialog v-model="dialog" persistent max-width="40%">
      <v-card class="comments-view">
        <v-toolbar>
          <v-toolbar-title>Select Send Data To IB </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.prevent="close()">
            <v-icon class="heading grey--text text--darken-4">close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer class="invoice-pop">
          <v-card-text class="nospace">
            <v-layout>
              <v-flex>
                <v-radio-group
                  class="typebox"
                  v-model="selected"
                  style="text-align: center !important"
                  mandatory
                >
                  <v-radio
                    label="Enable All Customer IB"
                    class="boxborder"
                    color="red"
                    value="ADD_TO_REAL_BOOK"
                  ></v-radio>

                  <v-radio
                    label="Disable All Customer IB"
                    value="REMOVE_FROM_REAL_BOOK"
                    class="boxborder"
                    color="red"
                  ></v-radio>
                  <v-radio
                    label="Enable/Disable Customer Manaually"
                    value="openCheckBox"
                    class="boxborder"
                    color="red"
                  ></v-radio>

                  <!--
                    <v-radio
                      label="Single Line Invoice For Multiple Jobs"
                      class="boxborder"
                      value="singleLineWithMultipleJobsBasis"
                      color="red"
                      :disabled="jobIds.length == 1"
                    ></v-radio> -->
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-spacer />
              <v-btn
                size="large"
                color="grey darken-3"
                flat
                style="color: white"
                @click.native="close"
                >Cancel</v-btn
              >

              <v-btn
                style="background: orange !important"
                flat
                target="_blank"
                @click="chooseDataForIb()"
                prepend-icon
                color="white darken-1"
              >
                Yes</v-btn
              >
            </v-layout>
          </v-card-text>
        </v-spacer>
      </v-card>
      <v-snackbar
        :timeout="4000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-dialog>
    <v-dialog v-model="dialogIb" persistent max-width="40%">
      <v-card class="comments-view">
        <v-toolbar>
          <v-toolbar-title>Select Send Data To IB </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.prevent="close()">
            <v-icon class="heading grey--text text--darken-4">close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer class="invoice-pop">
          <v-card-text class="nospace">
            <v-layout>
              <v-flex>
                <v-radio-group
                  class="typebox"
                  v-model="selectedIbRadio"
                  style="text-align: center !important"
                  mandatory
                >
                  <v-radio
                    label="Enable customer to send the data to IB"
                    class="boxborder"
                    color="red"
                    value="ADD_TO_REAL_BOOK"
                  ></v-radio>

                  <v-radio
                    label="Disable  customer to send the data to IB"
                    value="REMOVE_FROM_REAL_BOOK"
                    class="boxborder"
                    color="red"
                  ></v-radio>

                  <!--
                    <v-radio
                      label="Single Line Invoice For Multiple Jobs"
                      class="boxborder"
                      value="singleLineWithMultipleJobsBasis"
                      color="red"
                      :disabled="jobIds.length == 1"
                    ></v-radio> -->
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-spacer />
              <v-btn
                size="large"
                color="grey darken-3"
                flat
                style="color: white"
                @click.native="close"
                >Cancel</v-btn
              >

              <v-btn
                style="background: orange !important"
                flat
                target="_blank"
                @click="SendSelectedCustomer()"
                prepend-icon
                color="white darken-1"
              >
                Yes</v-btn
              >
            </v-layout>
          </v-card-text>
        </v-spacer>
      </v-card>
      <v-snackbar
        :timeout="4000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-dialog>
  </v-card>
</template>
<script>
import SelectCountry from "../Common/SelectCountry";
import { StorageKeys } from "../../constants/constants";
import { ApiService } from "@/services/api-service";
export default {
  components: {
    SelectCountry,
  },
  props: ["dashboard", "selectedCustomerIb", "tabFilter"],
  data() {
    return {
      selectCountryDialog: false,
      error: "",
      dialog: false,
      dialogIb: false,
      showIbDetails: false,
      operationName: "",
      selected: [],
      selectedIbRadio: [],
      selectedCustomer: [],
    };
  },
  watch: {
    selectedCustomerIb(e) {
      this.selectedCustomer = e;
    },
  },
  methods: {
    async check() {
      this.operationName = "add-new-customer";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });
          if (workingCountry.length === 1) {
            this.$router.push(`/customer/new`);
          } else {
            this.selectCountryDialog = true;
          }
        }
      } else {
        return;
      }
    },

    SendIBPopup() {
      this.dialog = true;
    },
    close() {
      this.selected = [];
      this.selectedIbRadio = [];
      this.loading = false;
      this.dialog = false;
      this.dialogIb = false;
    },
    SendSelectedCustomer() {
      this.$eventBus.$emit("on-load");
      if (!this.selectedIbRadio.length) {
        this.error = "Please Select  Any Method For Send Data To IB !";
        return;
      }
      let url = "/dmsAdmin/update-ib-access-status";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        // custId: id.toString(),
        type: "ONE",
        usersIds: this.selectedCustomer,
        action: this.selectedIbRadio,
      };

      this.axios
        .put(this.constants.apiUrl + url, body, config)
        .then((response) => {
          this.dialogIb = false;
          this.$eventBus.$emit("off-load");
          this.$emit("checkBoxShow", false);
          this.selected = [];
          this.selectedIbRadio = [];
          this.selectedCustomer = [];
          this.$emit("resetIb");
          this.$emit("refreshIb");
        });
    },
    chooseDataForIb() {
      if (!this.selected.length) {
        this.error = "Please Select  Any Method For Send Data To IB !";
        return;
      } else {
        if (this.selected == "openCheckBox") {
          this.$emit("checkBoxShow", true);
          this.dialog = false;
        } else {
          let url = "/dmsAdmin/update-ib-access-status";
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let body = {
            // custId: id.toString(),
            type: "ALL",
            action: this.selected,
          };

          this.axios.put(this.constants.apiUrl + url, body, config).then(
            (response) => {
              this.dialog = false;
              this.selected = [];
            },
            (error) => {}
          );
        }
      }
    },

    CheckboxSelectedIb() {
      this.dialogIb = true;
    },
  },
};
</script>
<style scoped>
.dashboard {
  padding: 20px;
  margin: 0 auto;
}
.breakword {
  padding: 10px;
}
.p-b-0 {
  padding-bottom: 0 !important;
}

.f-16 {
  font-size: 16px !important;
}
.invoice-pop {
  padding: 20px !important;
}
.fonthead {
  font-size: 20px;
}
</style>
<style lang="scss">
.typebox {
  display: block !important;
  .boxborder {
    padding-bottom: 20px;
    margin-right: 11px;
    border-bottom: 1px solid lightgray;
  }
  .v-input--selection-controls__input {
    position: absolute !important;
    right: 0 !important;
  }
}
</style>
