<template>
  <div class style="padding-left: 5px">
    <template>
      <!-- <img
        @click.stop="dialog = false"
        v-if="this.$props.section === 'advance'"
        @click="check()"
        width="16"
        v-permissions="'activity-logs-for-accounts'"
        style="margin-right:0px !important;padding-left: 0px !important ;cursor: pointer;"
        src="../../assets/history.svg"
      /> -->

      <!-- <img
        @click.stop="dialog = false"
        v-if="this.$props.section='advance'"
        @click="check()"
        width="16"
        v-permissions="'activity-logs-for-accounts'"
        style="margin-right:0px !important;padding-left: 0px !important ;cursor: pointer;"
        src="../../assets/history.svg"
      /> -->

      <v-icon
        color="orange darken-1"
        class="white--text"
        flat
        @click.stop="dialog = false"
        @click="check()"
        :disabled="loading"
        title="Activity Log"
        >visibility</v-icon
      >
      <!-- <v-icon
        v-if="this.$route.name == 'Accounts' && !this.$props.subType"
        v-permissions="'activity-logs-for-accounts'"
        color="red"
        class="white--text"
        small
        @click.stop="dialog = false"
        @click="check()"
        title="Activity Log"
        >visibility</v-icon
      > -->
    </template>
    <v-dialog v-model="dialog" max-width="50%">
      <v-form ref="form">
        <v-card>
          <v-toolbar fixed flat>
            <!-- <v-toolbar-title v-if="type !== 'milestone'"
              >Viewing {{ subType ? "Advance" : "Activity" }} Logs for ID :
              {{ id }}
            </v-toolbar-title> -->
            <v-toolbar-title v-if="type === 'milestone'"
              >Viewing Milestone Activity Logs
            </v-toolbar-title>
            <div v-if="type !== 'milestone'">
              <v-toolbar-title v-if="subType == 7"
                >Viewing Payment Logs for {{ whichId || "" }} ID :
                {{ id }}
              </v-toolbar-title>

              <v-toolbar-title v-else-if="subType == 5"
                >Viewing Advance Logs for {{ whichId || "" }} ID :
                {{ id }}
              </v-toolbar-title>
              <v-toolbar-title v-else-if="subType == 9"
                >Viewing Logs For SI :
                {{ id }}
              </v-toolbar-title>
              <v-toolbar-title v-else-if="subType == 17"
                >Viewing Logs For Advance
              </v-toolbar-title>
              <v-toolbar-title v-else-if="subType == 15"
                >Viewing Logs For Receipt :
                {{ id }}
              </v-toolbar-title>
              <v-toolbar-title v-else-if="subType == 10"
                >Viewing Logs For Loss To Trukkin For SI :
                {{ id }}
              </v-toolbar-title>
              <v-toolbar-title v-else-if="subType == 16"
                >Viewing Logs For Additional Charges
                {{ id }}
              </v-toolbar-title>

              <v-toolbar-title v-else
                >Viewing Activity Logs for ID :
                {{ id }}
              </v-toolbar-title>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer class="spacerclass">
            <v-card-text v-if="activity.length">
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(data, i) in activity"
                  :key="i"
                  small
                  color="orange"
                >
                  <template> </template>
                  <div class="py-2">
                    <span class="timeline__item"> Edited By: </span>
                    {{ data.editedBy ? data.editedBy.name : "-" }}({{
                      data.editedBy ? data.editedBy.employeeID : "-"
                    }})

                    <div style="word-break: break-word">
                      <span class="timeline__item"> Comment: </span
                      >{{ data.comments }}
                    </div>
                    <span class="timeline__item"> Time: </span
                    >{{ getTime(data.editDate) }}
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
            <v-card-text v-else class="activityLog"
              >No Activity Log</v-card-text
            >
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import { ApiService } from "@/services/api-service";
import { StorageKeys } from "../../constants/constants";
import { activityLogs, activityLogsCustomer } from "@/constants/api-urls.js";
export default {
  data() {
    return {
      x: {
        error: null,
      },
      operationName: "",
      activity: [],
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    id: String,
    type: String,
    assignmentId: String,
    subType: Number,
    _id: String,
    permissions: String,
    section: String,
    whichId: String,
    sourceId: String,
  },
  created() {},
  components: {
    ErrorBox,
  },
  methods: {
    getIdforApi(type) {
      switch (type) {
        case "jobs":
          return this.$props._id;
        case "inquiry":
          return this.$props.id;
        case "requestTicket":
          return this.$props._id;
        case "driver":
          return this.$props.id;
        case "transporter":
          return this.$props._id;
        case "customer":
          return this.$props._id;
        case "shipmentType":
          return this.$props.id;
        case "assignment":
          return this.$props._id;
        case "advance":
          return this.$props._id;
        case "milestone":
          return this.$props._id;
        default:
          return this.$props.id;
      }
    },
    async check() {
      this.loading = true;
      if (this.$props.permissions) {
        this.operationName = this.$props.permissions;
        let y = await this.checkOpertaionPermission(this.operationName);
        if (y) {
          this.dialog = true;
          this.loading = false;
          this.getActivityLog();
        } else {
          this.loading = false;
          return;
        }
      } else {
        this.loading = false;
        this.dialog = true;
        this.getActivityLog();
      }
    },
    getTime(date) {
      return moment(date).format("ll hh:mm A");
    },

    getActivityLog() {
      if (this.processing) {
        return;
      } else {
        this.processing = true;
        if (this.$props.type == "1") {
          let body = {
            type: this.$props.type,
            entry_id: this.$props._id,
          };
          ApiService.postData(activityLogsCustomer, body).then(
            (response) => {
              this.dialog = true;
              this.activity = response.data.data;
              this.processing = false;
            },
            (error) => {
              this.x.error = "Failed to Fetch Activity Logs";
              this.processing = false;
            }
          );
        } else if (this.$props.type == "2") {
          let body = {
            type: "1",
            invoiceNumber: this.$props._id,
          };
          ApiService.postData(activityLogsCustomer, body).then(
            (response) => {
              this.dialog = true;
              this.activity = response.data.data;
              this.processing = false;
            },
            (error) => {
              this.x.error = "Failed to Fetch Activity Logs";
              this.processing = false;
            }
          );
        } else if (this.$props.type == "3") {
          let body = {
            type: "1",
            entry_id: this.$props._id,
          };
          ApiService.postData(activityLogsCustomer, body).then(
            (response) => {
              this.dialog = true;
              this.activity = response.data.data;
              this.processing = false;
            },
            (error) => {
              this.x.error = "Failed to Fetch Activity Logs";
              this.processing = false;
            }
          );
        } else if (this.$props.type == "4") {
          let body = {
            type: "1",
            lossTTId: this.$props._id,
          };
          ApiService.postData(activityLogsCustomer, body).then(
            (response) => {
              this.dialog = true;
              this.activity = response.data.data;
              this.processing = false;
            },
            (error) => {
              this.processing = false;
            }
          );
        } else if (this.$props.type == "12") {
          let body = {
            sourceId: this.$props.sourceId,
          };
          ApiService.postData(activityLogs, body).then(
            (response) => {
              this.dialog = true;
              this.activity = response.data.data;
              this.processing = false;
            },
            (error) => {
              this.x.error = "Failed to Fetch Activity Logs";
              this.processing = false;
            }
          );
        } else if (this.$props.type == "advance") {
          let body = {
            type: this.$props.type,
            id: this.$props.id,
          };
          ApiService.postData(activityLogs, body).then(
            (response) => {
              this.dialog = true;
              this.activity = response.data.data;
              this.processing = false;
            },
            (error) => {
              this.x.error = "Failed to Fetch Activity Logs";
              this.processing = false;
            }
          );
        } else {
          let body = {
            type: this.$props.type,
          };
          if (this.$props.subType) {
            body.subType = this.$props.subType;
          }
          if (this.$props.assignmentId) {
            body.assignmentId = this.$props.assignmentId;
          }
          body.id = this.getIdforApi(this.$props.type);
          ApiService.postData(activityLogs, body).then(
            (response) => {
              this.dialog = true;
              this.activity = response.data.data;
              this.processing = false;
            },
            (error) => {
              this.x.error = "Failed to Fetch Activity Logs";
              this.processing = false;
            }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.activityLog {
  margin: 30px auto;
  text-align: center;
  width: 80%;
}

.activity {
  margin: auto;
}
.tooltip {
  font-size: 18px;
  font-weight: 500;
}
.tooltip-op {
  left: calc(100% - 9.2%) !important;
  opacity: 1 !important;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.timeline__item {
  color: grey;
}
</style>
