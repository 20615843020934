<template>
  <v-layout class="pb-2 pr-2">
    <v-spacer />
    <v-btn
      right
      fab
      small
      :disabled="!prevUrl"
      :loading="prevLoading"
      class="mt-3 mx-2 pagination-back"
      @click.native="previous"
    >
      <v-icon>arrow_back</v-icon>
    </v-btn>
    <v-btn
      right
      fab
      small
      :disabled="!nextUrl"
      :loading="nextLoading"
      class="mt-3 mx-2 pagination-forward"
      @click.native="next"
    >
      <v-icon>arrow_forward</v-icon>
    </v-btn>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="x.error"
      class="white--text"
      v-if="x.error"
      >{{ x.error }}</v-snackbar
    >
  </v-layout>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import { EventBus } from "../../event-bus.js";
export default {
  created() {
    this.nextUrl = this.url;
    this.body = this.payload;
    this.load();
  },
  components: {
    ErrorBox,
  },

  props: {
    url: String,
    callback: Function,
    payload: Object,
    componentKey: Number,
  },
  watch: {
    componentKey: {
      handler(val) {
        this.load();
      },
      deep: true,
    },
    payload: {
      handler(val) {
        this.body = val;
        this.load();
      },
      deep: true,
    },
  },

  data() {
    return {
      callLoad: null,

      prevUrl: null,
      prevLoading: false,
      nextUrl: null,
      body: null,
      nextLoading: false,
      x: {
        error: null,
      },
    };
  },
  methods: {
    load() {
      if (parseInt(this.payload.skip) > 0) {
        this.prevUrl = true;
      } else {
        this.prevUrl = false;
      }

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      this.axios.post(this.constants.apiUrl + this.url, this.body, config).then(
        (response) => {
          const res = response.data.data;

          this.callback(res);
          if (res.list) {
            if (
              res.list.length >= this.payload.limit &&
              res.totalData >
                parseInt(this.payload.skip) + parseInt(this.payload.limit)
            ) {
              this.nextUrl = true;
            } else {
              this.nextUrl = false;
            }
          } else if (res.jobInfo) {
            if (
              res.jobInfo.length >= this.payload.limit &&
              res.totalData >
                parseInt(this.payload.skip) + parseInt(this.payload.limit)
            ) {
              this.nextUrl = true;
            } else {
              this.nextUrl = false;
            }
          } else {
            this.nextUrl = false;
            //this.prevUrl-false;
          }

          // $("html, body").animate({ scrollTop: 0 }, 600);
        },
        (error) => {
          if (!navigator.onLine) {
            this.loading = false;
            this.x.error = "Please check your internet connection";
            setTimeout(() => {
              this.x.error = "";
            }, 3000);
            return;
          }
          this.x.error = "Failed to Fetch Data";
          this.loading = false;
          this.$eventBus.$emit("stop-load");
        }
      );
    },

    next() {
      if (this.nextUrl) {
        if (parseInt(this.body.skip) == 0) {
          this.body.skip = parseInt(this.body.limit);
        } else {
          this.body.skip = parseInt(this.body.skip) + parseInt(this.body.limit);
        }
        this.body.skip = this.body.skip.toString();
        this.prevUrl = true;
        this.nextLoading = true;
        this.callLoad = false;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios
          .post(this.constants.apiUrl + this.url, this.body, config)
          .then(
            (response) => {
              const res = response.data.data;
              this.callback(res);
              this.nextLoading = false;
            },
            (error) => {
              this.loading = false;
              this.x.error = "Failed to Fetch Data";
            }
          );
      }
    },
    previous() {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      //   this.$emit("pagination-payload", this.payload);

      if (this.prevUrl) {
        this.prevLoading = true;

        if (parseInt(this.body.skip) == 0) {
          this.body.skip = 0;
        } else {
          this.body.skip = parseInt(this.body.skip) - parseInt(this.body.limit);
        }
        if (this.body.skip < 1) {
          this.prevUrl = null;
        }

        this.body.skip = this.body.skip.toString();
        this.callLoad = false;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios
          .post(this.constants.apiUrl + this.url, this.body, config)
          .then(
            (response) => {
              const res = response.data.data;
              this.callback(res);

              this.prevLoading = false;
            },
            (error) => {
              this.loading = false;
              this.x.error = "Failed to Fetch Data";
            }
          );
      }
    },
  },
};
</script>

<style></style>
