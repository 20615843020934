<template>
  <div class="log">
    <v-icon
      color="brown darken-1"
      class="white--text"
      flat
      @click.stop="dialog = true"
      @click="getData"
      title="Notification"
      >email</v-icon
    >
    <!-- <button
      @click.stop="dialog = true"
      title="View Customer Details"
      @click="checkStatus"
      class="view-ass-btn"
    >Email</button>-->

    <v-dialog v-model="dialog" persistent max-width="30%">
      <v-form ref="form" onSubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Manage Email Updates</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer class="address-pop">
            <v-card-text class="nospace">
              <v-layout>
                <v-flex md3></v-flex>
                <v-switch
                  v-model="ReceiveEmails"
                  color="green"
                  @change="emailNoyify"
                  label="Receive Emails"
                  class="switch-permission-type"
                ></v-switch>
                <v-flex md3></v-flex>
              </v-layout>
              <v-layout>
                <v-flex md3></v-flex>
                <v-switch
                  v-model="JobStatus"
                  color="green"
                  @change="emailNoyify"
                  :disabled="!ReceiveEmails || this.radios == 'Summary'"
                  label="Job Status Emails"
                  class="switch-permission-type"
                ></v-switch>
                <v-flex md3></v-flex>
              </v-layout>
              <v-layout>
                <v-flex md3></v-flex>
                <v-switch
                  v-model="AssignmentStatus"
                  :disabled="!ReceiveEmails || this.radios == 'Summary'"
                  @change="emailNoyify"
                  color="green"
                  label="Assignment Status Emails"
                  class="switch-permission-type"
                ></v-switch>
                <v-flex md1></v-flex>
              </v-layout>
              <v-layout>
                <v-flex md2></v-flex>
                <v-radio-group
                  class="mt-0 pt-0"
                  @change="emailNoyify"
                  v-model="radios"
                  :disabled="!ReceiveEmails"
                  row
                  :mandatory="true"
                >
                  <div class="subheading muted" style="padding-right: 10px">
                    Email Type
                  </div>
                  <div class="heading">
                    <v-layout>
                      <v-radio
                        row
                        label="All"
                        value="All"
                        color="green"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        row
                        label="Summary"
                        value="Summary"
                        color="green"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </v-radio-group>
              </v-layout>
            </v-card-text>
            <v-snackbar
              :timeout="3000"
              bottom
              color="red darken-2"
              v-model="x.error"
              class="white--text"
              v-if="x.error"
              >{{ x.error }}</v-snackbar
            >
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
export default {
  data() {
    return {
      loading: false,
      x: {
        error: null,
      },
      radios: "All",
      toggle_exclusive: 0,
      ReceiveEmails: null,
      JobStatus: null,
      AssignmentStatus: null,
      processing: false,
      reason: "",
      dialog: false,

      loading: false,
    };
  },
  props: {
    detail: Object,
  },
  created() {},

  methods: {
    getData() {
      this.loading = true;
      let Url = "/dmsAdmin/getUserEmailRoles";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        userId: this.detail.userId.toString(),
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          if (response.data.statusCode == 200) {
            this.radios = response.data.data[0].emailType;
            this.JobStatus = response.data.data[0].jobStatusForEmail;
            this.AssignmentStatus =
              response.data.data[0].assignmentStatusForEmail;
            this.ReceiveEmails = response.data.data[0].isReceiveEmail;
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;

          this.x.error = error.response.data.message;
          // setTimeout(()=>{this.error='';}, 6000);
        }
      );
    },
    emailNoyify() {
      this.checkStatus();
      this.loading = true;
      let Url = "/dmsAdmin/editUserEmailRoles";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        userId: this.detail.userId.toString(),
        emailType: this.radios,
        jobStatusForEmail: this.JobStatus,
        assignmentStatusForEmail: this.AssignmentStatus,
        isReceiveEmail: this.ReceiveEmails,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          if (response.data.statusCode == 200) {
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.x.error = error.response.data.message;
        }
      );
    },

    checkStatus() {
      if (this.radios == "Summary") {
        this.JobStatus = false;
        this.AssignmentStatus = false;
      }
      if (this.ReceiveEmails == false) {
        this.JobStatus = false;
        this.radios = "All";
        this.AssignmentStatus = false;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.switch-permission-type {
  width: 25%;
  display: inline-block;
  float: left;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
