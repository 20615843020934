<template>
  <v-layout>
    <button
      v-if="type !== 'driverApprove'"
      @click="type == 'bidAccept' ? '' : check()"
      class="track-ass-btn green-button m-t-10 m-l-10"
    >
      Approve
    </button>
    <button
      v-if="type === 'driverApprove'"
      @click="type == 'bidAccept' ? '' : check()"
      class="track-ass-btn green-button m-t-10"
    >
      Approve
    </button>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">{{ confirmationTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12 md12 class="text-style">
                {{ message }}
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  class="currencyTitle"
                  v-if="type === 'driverApprove'"
                  type="text"
                  single-line
                  label="Enter your reason"
                  :rules="[rules.required, rules.noWhiteSpace]"
                  required
                  v-model="confirmReason"
                  :value="ConfirmReason"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black darken-1" flat @click="dialog = false">{{
              cancelBtn
            }}</v-btn>
            <v-btn
              color="success"
              @click="approveDriverPrice()"
              v-if="type == 'driverApprove'"
            >
              {{ confirmBtn }}
            </v-btn>
            <v-btn color="success" v-else @click="approveCustomer()">
              {{ confirmBtn }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import { StorageKeys } from "../../constants/constants";
import { verifyCustomerAccount } from "../../constants/api-urls";
export default {
  props: {
    message: String,
    confirmationTitle: String,
    customerData: Object,
    cancelBtn: String,
    confirmBtn: String,
    type: String,
    submitFunction: Function,
    assignmentId: String,
  },
  data: () => ({
    loading: false,
    valid: true,
    lazy: false,
    operationName: "",
    dialog: false,
    error: null,
    confirmReason: "",
    rules: {
      required: (value) => !!value || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
    },
  }),
  created() {},
  methods: {
    async check() {
      this.operationName = "approve-customer";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog = true;
      } else {
        return;
      }
    },
    async approveCustomer() {
      this.operationName = "approve-customer";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        let url = verifyCustomerAccount;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          isVerifiedByAccounts: "Verified",
          userId: this.customerData.userId,
          workingCountry: this.customerData.workingCountry,
        };

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            // this.x.success = true;
            this.dialog = false;
            this.$emit("refresh-list");
          },
          (error) => {
            this.error = error.response.data.message;
          }
        );
      } else {
        return;
      }
    },
    async approveDriverPrice() {
      if (!this.confirmReason.trim().length > 0) {
        this.error = "Please enter reason for approval";
        return;
      }
      console.log("approve Price");
      let url = "/dmsAdmin/approve/driverPrice";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        isApprove: "true",
        assignmentId: this.assignmentId.toString(),
        cancelReason: this.confirmReason,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          // this.x.success = true;
          this.dialog = false;
          this.$emit("refresh-list");
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style scoped>
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.text-style {
  font-size: 16px;
}
</style>
