<template>
  <v-layout>
    <button
      @click="check()"
      v-if="type != 'driverApprove'"
      class="cancel-ass-btn red-button m-t-10 m-l-10"
    >
      Reject
    </button>
    <button
      @click="check()"
      v-if="type === 'driverApprove'"
      class="cancel-ass-btn red-button m-t-10"
    >
      Reject
    </button>

    <v-dialog v-model="dialog" persistent max-width="700">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">{{ confirmationTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex class="text-style">
                {{ message }}
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex pt-2 pr-1>
                <!-- <v-select
                  class="pt-0 currencyTitle"
                  v-model="cancelType"
                  :items="cancellationType"
                  label="Select your option"
                  :disabled="cancelType.value == 'Other’s'"
                  persistent-hint
                  :menu-props="{ offsetY: true }"
                  return-object
                  single-line
                ></v-select> -->
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  class="pt-0 currencyTitle"
                  v-if="cancelType.value == 'Other’s'"
                  type="text"
                  single-line
                  label="Enter your reason"
                  :rules="[rules.required, rules.noWhiteSpace]"
                  required
                  v-model="cancellationReason"
                  :value="cancellationReason"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black darken-1" flat @click="close()">{{
              cancelBtn
            }}</v-btn>
            <v-btn
              color="error"
              @click="rejectDriverPrice"
              v-if="type == 'driverApprove'"
            >
              {{ confirmBtn }}
            </v-btn>
            <v-btn color="error" @click="rejectCustomer" v-else>
              {{ confirmBtn }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import { StorageKeys, CancellationType } from "../../constants/constants";
import { verifyCustomerAccount } from "../../constants/api-urls";
export default {
  props: {
    message: String,
    confirmationTitle: String,
    cancelBtn: String,
    confirmBtn: String,
    customerData: Object,
    assignmentId: String,
    type: String,
  },
  data: () => ({
    loading: false,
    valid: true,
    lazy: false,
    cancellationType: CancellationType,
    rules: {
      required: (value) => !!value || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
    },
    cancellationReason: "",
    cancelType: { text: "Other’s", value: "Other’s" },
    operationName: "",
    dialog: false,
    error: null,
  }),
  created() {},
  methods: {
    async check() {
      this.operationName = "reject-customer";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.dialog = true;
      } else {
        return;
      }
    },
    close() {
      this.dialog = false;
      this.cancellationReason = "";
      this.$refs.form.resetValidation();
    },
    async rejectCustomer() {
      if (this.$refs.form.validate()) {
        this.operationName = "reject-customer";
        let y = await this.checkOpertaionPermission(this.operationName);
        if (y) {
          let url = verifyCustomerAccount;
          delete this.axios.defaults.headers.common["token"];
          let token = localStorage.getItem(StorageKeys.SessionKey);
          let config = {
            headers: {
              authorization: `bearer ${token}`,
            },
          };
          let body = {
            isVerifiedByAccounts: "Rejected",
            userId: this.customerData.userId,
            workingCountry: this.customerData.workingCountry,
          };
          if (this.cancelType.value === "Other’s") {
            body.comments = this.cancellationReason;
          } else {
            body.comments = this.cancelType.value;
          }

          this.axios.post(this.constants.apiUrl + url, body, config).then(
            (response) => {
              // this.x.success = true;
              this.close();
              this.$emit("refresh-list");
            },
            (error) => {
              this.error = error.response.data.message;
            }
          );
        } else {
          return;
        }
      }
    },
    async rejectDriverPrice() {
      console.log("tryyyy");
      console.log(this.cancellationReason);
      if (!this.cancellationReason.trim().length > 0) {
        this.error = "Please enter reason for rejection";
        return;
      }

      let url = "/dmsAdmin/approve/driverPrice";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        isApprove: "false",
        assignmentId: this.assignmentId.toString(),
        cancelReason: this.cancellationReason,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          // this.x.success = true;
          this.dialog = false;
          this.$emit("refresh-list");
        },
        (error) => {
          this.error = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style scoped>
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.cancel-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 12px;
}

.text-style {
  font-size: 16px;
}
</style>
