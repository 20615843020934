<template>
  <div class="log">
    <button
      @click.stop="dialog = true"
      title="View Customer Details"
      class="view-ass-btn"
    >
      View
    </button>

    <v-dialog v-model="dialog" max-width="50%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>View Customer Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer class="address-pop">
            <v-card-text class="nospace">
              <table
                id="jobsTable"
                class="v-datatable v-table theme--light"
                hide-actions
              >
                <tr>
                  <td>
                    Customer ID
                  </td>
                  <td>
                    {{ detail.customerSerialNumber || detail.customerId }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Customer Name
                  </td>
                  <td>
                    {{ detail.name || detail.customer || detail.companyName }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Email
                  </td>
                  <td>{{ detail.email || detail.customerEmail }}</td>
                </tr>
                <tr>
                  <td>
                    Phone
                  </td>
                  <td>
                    <span v-if="detail.phoneNo && detail.customerCountryCode">
                      {{
                        detail.customerCountryCode +
                          "-" +
                          detail.phoneNo.replace(detail.customerCountryCode, "")
                      }}</span
                    >
                    <span
                      v-else-if="
                        detail.customerPhoneNumber && detail.customerCountryCode
                      "
                    >
                      {{
                        detail.customerCountryCode +
                          "-" +
                          detail.customerPhoneNumber.replace(
                            detail.customerCountryCode,
                            ""
                          )
                      }}</span
                    >
                    <span v-else>
                      {{ detail.phoneNo || detail.customerPhoneNumber }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    Company Name
                  </td>
                  <td>{{ detail.companyName }}</td>
                </tr>
                <tr>
                  <td>
                    Country
                  </td>
                  <td>{{ detail.country || detail.workingCountry }}</td>
                </tr>
              </table>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      x: {
        error: null,
      },
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    detail: Object,
  },
  created() {
    // this.getActivityLog();
  },

  methods: {},
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
