<template>
  <v-container fluid>
    <v-layout row pt-3>
      <v-flex xs2 md3>
        <v-select
          v-model="searchTypes"
          :items="searchItems"
          flat
          label="Search Type"
          item-text="status"
          single-line
          class="pt-0"
          item-value="abbr"
          persistent-hint
          @change="searchByType()"
          height="10"
          return-object
          outline
        ></v-select>
      </v-flex>
      <v-flex xs2 md3>
        <v-text-field
          height="55px"
          class="gap"
          label="Search here.."
          outline
          :disabled="!searchTypes"
          single-line
          v-model="search"
          @keyup.enter="searchAnything"
          @click:append="searchAnything"
          append-icon="search"
        ></v-text-field>
      </v-flex>
      <v-flex xs2 md2 pl-2 pr-2>
        <v-select
          v-model="isAssigned"
          :items="Executive"
          item-text="status"
          item-value="abbr"
          label="Executive"
          persistent-hint
          return-object
          @change="searchByExecutive()"
          single-line
        ></v-select>
      </v-flex>

      <v-flex xs1 md2 pl-2 pr-2>
        <v-select
          v-model="isAccountVerified"
          :items="Status"
          item-text="status"
          item-value="abbr"
          label="OTP Status"
          persistent-hint
          return-object
          @change="searchByStatus()"
          single-line
        ></v-select>
      </v-flex>
      <!-- <v-flex xs1 md2 pl-2 pr-2 v-if="tabFilter == 'false'">
        <v-select
          v-model="isApproved"
          :items="unverifiedStatus"
          item-text="status"
          item-value="abbr"
          label="Status"
          persistent-hint
          return-object
          @change="searchByUnverifiedStatus()"
          single-line
        ></v-select>
      </v-flex> -->

      <!-- <v-flex xs2 md2 pl-2 pr-2>
        <v-select
          v-model="sort"
          :items="sorting"
          item-text="status"
          item-value="abbr"
          label="Sorting By"
          persistent-hint
          return-object
          @change="sortBy()"
          single-line
        ></v-select>
      </v-flex> -->

      <v-flex xs2 md2 pl-2 pr-2>
        <v-autocomplete
          v-model="executiveFilter"
          :items="executives"
          item-text="name"
          item-value="_id"
          label="Filter By Executive"
          persistent-hint
          return-object
          @change="filterByExecutive()"
          single-line
        ></v-autocomplete>
      </v-flex>
      <v-flex xs3 pl-3 style="padding-top: 18px" class="wrapper" id="jobDate">
        <ejs-daterangepicker
          :placeholder="waterMark"
          v-model="dateRange2"
        ></ejs-daterangepicker>
      </v-flex>
      <v-flex xs1 md1 class="reset">
        <v-btn
          class="reset-btn-color"
          flat
          prepend-icon
          color="orange darken-1"
          @click.native="reset()"
        >
          Reset
          <v-icon color="orange">donut_large</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { StorageKeys, JobListTypes } from "../../constants/constants";
Vue.use(DateRangePickerPlugin);

import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { EventBus } from "../../event-bus.js";
export default {
  name: "customFilterCustomer",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      startDate: "",
      sort: "",
      waterMark: "Select a Range",
      dateRange2: null,
      dateRange: { startDate: "", endDate: "" },
      Status: [
        { status: "Verified", abbr: "active" },
        { status: "Not Verified", abbr: "inactive" },
      ],
      unverifiedStatus: [
        { status: "Approved", abbr: true },
        { status: "Rejected", abbr: false },
      ],
      sorting: [
        { status: "Maximum Booking", abbr: "booking desc" },
        { status: "Minimum Booking", abbr: "booking asc" },
        { status: "Maximum Inquiry", abbr: "inquiry desc" },
        { status: "Minmum Inquiry", abbr: "inquiry asc" },
        { status: "Company Name Asc", abbr: "company asc" },
        { status: "Company Name Desc", abbr: "company desc" },
      ],
      Executive: [
        { status: "Assigned", abbr: "true" },
        { status: "Unassigned", abbr: "false" },
      ],

      searchItems: [
        { status: "Customer ID", abbr: "custId" },
        { status: "Company Name/Customer Name", abbr: "companyName" },
        { status: "Customer Email ID", abbr: "email" },
        { status: "Phone Number", abbr: "mobileNumber" },
      ],
      select: { country: "", abbr: "" },
      search: "",
      isAccountVerified: "",
      isApproved: "",
      isAssigned: "",
      executiveFilter: null,
      executives: [],
      searchTypes: "",
      items: [
        { country: "India", abbr: "IN" },
        { country: "United Arab Emirates", abbr: "UAE" },
        { country: "USA", abbr: "USA" },
        { country: "UK", abbr: "UK" },
      ],
    };
  },
  props: {
    tabFilter: String,
    resetfilter: Boolean,
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.fetchExecutives();
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "customer-section") {
        this.workingCountry = JSON.stringify(val);
        this.fetchExecutives();
      }
    });
  },
  watch: {
    dateRange2() {
      this.$emit("daterangenew-cust", this.dateRange2);
    },
    tabFilter(value) {
      this.isApproved = value;
    },
    resetfilter(value) {
      if (value) {
        this.executiveFilter = "";
      }
    },
  },
  methods: {
    fetchExecutives() {
      let url = `/sales/executiveList?workingCountry=${this.workingCountry}`;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios.get(this.constants.apiUrl + url, config).then(
        (response) => {
          this.executives = response.data.data;
        },
        (error) => {}
      );
    },
    reset() {
      // localStorage.removeItem("searchCustomer");
      // localStorage.removeItem("statusCustomer");
      // localStorage.removeItem("executiveCustomer");
      // localStorage.removeItem("searchCustomerType");
      // localStorage.removeItem("customerStartdateFilter");
      // localStorage.removeItem("customerEnddateFilter");

      this.$emit("customer-reset", this.search);
      this.searchTypes = "";
      this.search = "";
      this.sort = "";

      this.dateRange2 = null;
      this.isAccountVerified = null;
      this.isAssigned = "";
      this.executiveFilter = null;
    },
    filterByCountry() {
      this.$emit("job-Filter", this.select.country);
    },

    searchByStatus() {
      //  localStorage.setItem("statusCustomer", this.isAccountVerified.abbr);
      this.$emit("cust-status", this.isAccountVerified.abbr);
    },
    // searchByUnverifiedStatus() {
    //   if (this.tabFilter === "true") {
    //     this.isApproved = null;
    //   }
    //   this.$emit("cust-status-unverified", this.isApproved.abbr);
    // },
    sortBy() {
      //  localStorage.setItem("customerSortBy", this.sort.abbr);
      this.$emit("sort-By", this.sort.abbr);
    },
    searchByExecutive() {
      localStorage.setItem("executiveCustomer", this.isAssigned.abbr);
      this.$emit("cust-executive", this.isAssigned.abbr);
    },
    filterByExecutive() {
      // localStorage.setItem("FilterCustomer", this.executives);
      this.$emit("finance-executive", this.executiveFilter._id);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    searchAnything() {
      // localStorage.setItem("searchCustomer", this.search);
      // localStorage.setItem(
      //   "searchCustomerType",
      //   JSON.stringify(this.searchTypes)
      // );

      this.$emit("customer-search", this.search);
      this.$emit("filter-type", this.searchTypes.abbr);
    },
    searchByType() {
      this.search = "";
      this.$emit("filters-type", this.searchTypes.abbr);
      this.$emit("customer-search", this.search);
    },
  },
};
</script>
<style lang="scss">
#jobDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#jobDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  text-align: right;
}
#jobDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#jobDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#jobDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
</style>
<style scoped>
.search {
  float: left;
}

.country {
  display: inline-block;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
</style>
