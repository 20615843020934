<template>
  <v-container fluid>
    <v-card raised v-if="showScreen">
      <loading :active.sync="loading" />
      <v-tabs
        fixed-tabs
        class="m-b-20"
        background-color="darkorange"
        dark
        v-model="changeTab"
      >
        <!-- <v-tab @click="allJobs()">
                All Jobs<span class="tab-span">123</span>
                </v-tab> -->
        <v-tab :href="`#true`" @click="filterByTab('true')">
          Verified({{ dashboard.verified }})
        </v-tab>
        <v-tab :href="`#false`" @click="filterByTab('false')">
          Non-verified({{ dashboard.notVerified }})
        </v-tab>
        <!-- <v-tab :href="`#lead`" @click="filterByTab('lead')"> Leads </v-tab> -->
      </v-tabs>
      <v-flex>
        <Dashboard
          :dashboard="dashboard"
          v-on:checkBoxShow="checkboxc"
          v-on:resetIb="resetCustomer"
          v-on:refreshIb="refreshActivity"
          :selectedCustomerIb="selectedIbList"
          :tabFilter="changeTab"
        />
      </v-flex>
      <v-flex>
        <CustomFilter
          v-on:customer-search="searchText"
          v-on:customer-countryFilter="countryFilter"
          v-on:daterangenew-cust="dateRangenew"
          v-on:customer-reset="resetCustomer"
          v-on:filters-type="TypeFilter"
          v-on:cust-status="isAccountVerified"
          :tabFilter="changeTab"
          v-on:daterange-cust="dateRange"
          v-on:sort-By="sortBy"
          v-on:cust-executive="isAssigned"
          v-on:finance-executive="executiveFilter"
          v-on:filter-type="Type"
          :resetfilter="reset"
        />
      </v-flex>
      <v-flex>
        <CustomerList
          :dashboardData="dashboardData"
          :searchText="search"
          :dateRangenew="date2"
          :dateRange="date"
          :sortBy="sort"
          :isAccountVerified="status"
          :isAssigned="executive"
          :executiveFilter="executiveId"
          :tabFilter="changeTab"
          :countryFilter="country"
          :reset="reset"
          :loader="loading"
          :TypeFilter="filtertype"
          :Type="filter"
          :checkBoxShow="checkboxShow"
          v-on:on-block="refreshActivity"
          v-on:selectIb="selectedIb"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import Dashboard from "./Dashboard";
import CustomerList from "./CustomerList";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import CustomFilter from "./CustomFilter";
import moment from "moment";
import Loading from "vue-loading-overlay";
export default {
  mixins: [checkPermissionsMixin],
  created() {
    if (this.$route.query && this.$route.query.type) {
      if (this.$route.query.type === "false") {
        this.changeTab = "false";
      } else {
        this.changeTab = "true";
      }
    }
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
    });

    this.$eventBus.$on("on-load", () => {
      this.loading = "true";
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
  },
  mounted() {
    // if (localStorage.getItem("changeTab") != null) {
    //   this.changeTab = localStorage.getItem("changeTab");
    // } else {
    //   this.changeTab = "active";
    // }
  },
  components: {
    Dashboard,
    CustomerList,
    CustomFilter,
    Loading,
  },

  data: () => ({
    search: "",
    date: "",
    sort: "",
    country: "",
    date2: null,
    selectedIbList: [],
    status: null,
    approvedStatus: null,
    executive: "",
    executiveId: "",
    checkboxShow: false,
    showScreen: true,
    filtertype: "",
    filter: "",
    dashboard: {
      today: 0,
      total: 0,
      verified: 0,
      notVerified: 0,
    },
    active: "0",
    dialog: false,
    reset: false,
    loading: true,
    changeTab: "true",
  }),
  methods: {
    selectedIb(event) {
      this.selectedIbList = event;
    },
    filterByTab(value) {
      if (value === "false") {
        this.approvedStatus = null;
        this.$router.replace({
          name: "customer-section",
          query: { type: "false" },
        });
      } else {
        this.$router.replace({
          name: "customer-section",
          query: { type: "true" },
        });
      }

      //  localStorage.setItem("changeTab", value);
      this.changeTab = value;
    },
    refreshActivity() {
      console.log("refresh-activity");
      this.reset = true;
    },
    startLoad() {
      this.loading = true;
    },
    checkboxc(e) {
      this.checkboxShow = e;
    },
    stopLoad() {
      this.loading = false;
    },
    dateRangenew(event) {
      this.date2 = event;
      this.reset = false;
    },
    executiveFilter(event) {
      this.executiveId = event;
      this.reset = false;
    },

    resetCustomer() {
      this.reset = true;
      this.search = "";
      this.status = null;
      this.approvedStatus = null;
      this.checkboxShow = false;
      this.selectedIbList = [];

      this.sort = "";
      this.executive = "";
      this.executiveId = "";
      this.date = "";
      this.date2 = null;
      this.executiveId = null;

      //this.isAccountVerified='';
    },
    dashboardData(event) {
      this.dashboard.total = event.totalData;
      this.dashboard.today = event.todayData;
      this.dashboard.verified = event.totalVerified;
      this.dashboard.notVerified = event.totalNotVerified;
      this.loading = false;
    },
    searchText(event) {
      this.search = event;
      this.reset = false;
    },
    isAccountVerified(event) {
      this.status = event;
      this.reset = false;
    },
    dateRange(event) {
      this.date = event;
      this.reset = false;
    },
    sortBy(event) {
      this.sort = event;
      this.reset = false;
    },
    isAssigned(event) {
      this.executive = event;
      this.reset = false;
    },
    executiveFilter(event) {
      this.executiveId = event;
      this.reset = false;
    },
    countryFilter(event) {
      this.country = event;
      this.reset = false;
    },
    TypeFilter(event) {
      this.filtertype = event;
      this.reset = false;
    },
    Type(event) {
      this.filter = event;
      this.reset = false;
    },
  },
};
</script>

<style scoped>
.m-b-20 {
  margin-bottom: 20px !important;
}
</style>
